<template>
  <div class="page">
    <div class="title">角色管理</div>

    <div class="filter_box">
      <div class="filter_search">
        <el-input
          class="filter_search_input"
          placeholder="请输入关键词"
          v-model="keyword"
          clearable
        >
        </el-input>
        <div class="custom_button no_select" @click="searchLabour">搜索</div>
        <div
          class="custom_button plain no_select"
          @click="showAddCompany = true"
        >
          添加劳务公司
        </div>
      </div>
    </div>
    <el-dialog
      :title="labourId ? '编辑劳务公司' : '添加劳务公司'"
      :visible.sync="showAddCompany"
      :close-on-click-modal="false"
      @close="resetForm('ruleForm')"
      width="35%"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="110px"
        class="demo-ruleForm"
        labelPosition="right"
      >
        <el-form-item label="劳务公司名称" prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入劳务公司名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="劳务发放系数" prop="ratio">
          <el-input
            v-model="ruleForm.ratio"
            type="number"
            placeholder="请输入劳务发放系数"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="管理费" prop="manager">
          <el-input
            v-model="ruleForm.manager"
            placeholder="请输入管理费,并可选择单位"
          >
            <template slot="append">
              <el-select v-model="managerValue" placeholder="请选择">
                <el-option
                  v-for="item in managerPptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="税金" prop="taxation">
          <el-input v-model="ruleForm.taxation" placeholder="请输入税金">
            <template slot="append">%</template>
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-button @click="resetForm('ruleForm')">取消</el-button>

          <el-button
            type="primary"
            @click="addLabour('ruleForm')"
            v-loading.fullscreen.lock="fullscreenLoading"
            >{{ labourId ? "立即编辑" : "立即创建" }}</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-drawer title="劳务公司详情" :visible.sync="showDrawer" direction="rtl">
      <div class="draw_body">
        <div v-for="(item, index) in labourDeatil" :key="index">
          <span class="draw_span"></span>
          <span class="company_name">{{ item.name }}</span>
          <span>{{ item.value }}</span>
        </div>
      </div>
    </el-drawer>

    <el-table
      id="table"
      :data="tableData"
      :header-cell-style="tableHeaderStyle"
      :cell-style="tableCellStyle"
      style="width: 100%"
    >
      <el-table-column prop="name" label="劳务公司名称"></el-table-column>
      <el-table-column prop="ratio" label="劳务发放系数"></el-table-column>
      <el-table-column label="管理费">
        <template slot-scope="{ row }">
          <div>
            <span v-if="Number(row.manager_amount) > 0"
              >{{ row.manager_amount }}元</span
            >
            <span v-else>{{ row.manager_rate }}%</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="taxation" label="税金"></el-table-column>
      <el-table-column label="操作" min-width="180" fixed="right">
        <template v-slot:default="scope">
          <div class="operations">
            <div>
              <div @click="editLabour(scope.row)">编辑</div>
              <div>
                <el-popconfirm
                  @confirm="editStatus(scope.row)"
                  :title="`当前状态是${
                    scope.row.is_labour == 1 ? '启用' : '禁用'
                  },您要${
                    scope.row.is_labour == 0 ? '启用' : '禁用'
                  }此劳务公司吗?`"
                >
                  <div slot="reference">
                    {{ scope.row.is_labour == 1 ? "禁用" : "启用" }}
                  </div>
                </el-popconfirm>
              </div>
              <div>
                <el-popconfirm
                  @confirm="deleteLabour(scope.row)"
                  title="您要删除此劳务公司吗?"
                >
                  <div slot="reference">删除</div>
                </el-popconfirm>
              </div>

              <div @click="checkDetail(scope.row)">查看详情</div>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="prev, pager, next"
      :total="page.total"
      :page-size="page.size"
      :current-page.sync="page.page"
      @current-change="getLabourList"
    >
    </el-pagination>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  inject: ["reload"],
  data() {
    return {
      keyword: "",
      tableData: [],
      page: {
        size: 10,
        page: 1,
        total: 0,
      },
      showAddCompany: false,
      ruleForm: {
        name: "",
        ratio: "",
        manager: "",
        taxation: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入劳务公司名称", trigger: "blur" },
        ],
        ratio: [
          { required: true, message: "请输入劳务发放系数", trigger: "blur" },
        ],
        taxation: [{ required: true, message: "请输入税金", trigger: "blur" }],
        manager: [{ required: true, message: "请输入管理费", trigger: "blur" }],
      },
      managerValue: "manager_amount",
      managerPptions: [
        {
          value: "manager_amount",
          label: "元",
        },
        {
          value: "manager_rate",
          label: "%",
        },
      ],
      // 编辑劳务公司的id
      labourId: "",
      showDrawer: false,
      fullscreenLoading: false,
      labourDeatil: [],
      tableHeaderStyle: {
        backgroundColor: "#F5F8FA",
        textAlign: "center",
      },
      tableCellStyle: {
        textAlign: "center",
      },
    };
  },
  created() {
    this.getLabourList();
  },
  methods: {
    ...mapActions("enterprise", [
      "LabourList",
      "add_labour_company",
      "edit_labour_company",
      "edit_labour_status",
      "delete_labour",
      "get_labour_detail",
    ]),
    //劳务公司列表
    getLabourList() {
      this.fullscreenLoading = true;
      let { page, size } = this.page;
      let params = {
        page,
        size,
      };
      if (this.keyword.trim().length > 0) {
        params.name = this.keyword.trim();
      }
      this.LabourList(params).then(
        (res) => {
          console.log("劳务公司列表成功", res);
          let { code, ...data } = res.data;
          if (code == 200) {
            this.page.total = data.total;
            this.tableData = data.data.list.data;
          }
          this.fullscreenLoading = false;
        },
        (err) => {
          console.log("劳务公司列表失败", err);
          this.fullscreenLoading = false;
        }
      );
    },
    // 添加劳务公司 编辑劳务公司
    addLabour(formName) {
      this.fullscreenLoading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            name: this.ruleForm.name,
            ratio: this.ruleForm.ratio,
            taxation: this.ruleForm.taxation,
          };
          params[this.managerValue] = this.ruleForm.manager;

          let methodName = "add_labour_company";
          if (this.labourId) {
            params.id = this.labourId;
            // eslint-disable-next-line no-unused-vars
            methodName = "edit_labour_company";
          }
          console.log(params, methodName);

          this[methodName](params).then(
            (res) => {
              console.log("创建成功", res);
              this.alertMeaaage(res.data.message);
              this.resetForm();
              this.getLabourList();
            },
            (err) => {
              console.log("创建失败", err);
              this.alertMeaaage(err.message, "error");
              this.resetForm();
            }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //搜索公司
    searchLabour() {
      this.page.page = 1;
      this.getLabourList();
    },
    //编辑劳务公司
    editLabour(row) {
      console.log(row);
      this.showAddCompany = true;
      this.ruleForm = {
        name: row.name,
        ratio: row.ratio,
        manager: "",
        taxation: row.taxation,
      };
      this.labourId = row.id;
      this.ruleForm.manager =
        Number(row.manager_amount) > 0 ? row.manager_amount : row.manager_rate;
      this.managerValue =
        Number(row.manager_amount) > 0 ? "manager_amount" : "manager_rate";
    },
    // 编辑劳务公司状态
    editStatus(row) {
      let loading = this.getLoading();
      console.log(loading);
      this.edit_labour_status({
        id: row.id,
        is_labour: row.is_labour == 1 ? 0 : 1,
      }).then(
        (res) => {
          loading.close();
          console.log(res);
          this.getLabourList();
          this.alertMeaaage(res.data.message);
        },
        (err) => {
          loading.close();
          this.alertMeaaage(err.message, "error");
          console.log(err);
        }
      );
    },
    // 删除劳务公司
    deleteLabour(row) {
      let loading = this.getLoading();
      console.log(row);
      this.delete_labour({
        id: row.id,
      }).then(
        (res) => {
          console.log(res);
          loading.close();
          this.getLabourList();
          this.alertMeaaage(res.data.message);
        },
        (err) => {
          loading.close();
          this.alertMeaaage(err.message, "error");
          console.log(err);
        }
      );
    },
    // 查看详情
    checkDetail(row) {
      let loading = this.getLoading();
      console.log(row);
      this.get_labour_detail({
        id: row.id,
      }).then(
        (res) => {
          this.showDrawer = true;
          loading.close();

          if (res.data.code == 200) {
            let detailObj = res.data.data;
            let labourDeatil = [
              {
                name: "劳务公司名称",
                value: detailObj.name,
              },
              {
                name: "管理费",
                value:
                  Number(detailObj.manager_amount) > 0
                    ? detailObj.manager_amount + "元"
                    : detailObj.manager_rate + "%",
              },
              {
                name: "状态",
                value: detailObj.is_labour == 1 ? "启用" : "禁用",
              },
              {
                name: "创建时间",
                value: detailObj.create_time,
              },
              {
                name: "更新时间",
                value: detailObj.update_time,
              },
            ];

            this.labourDeatil = labourDeatil;
          }

          console.log(res);
        },
        (err) => {
          loading.close();
          this.alertMeaaage(err.message, "error");
          console.log(err);
        }
      );
    },
    resetForm(formName = "ruleForm") {
      this.fullscreenLoading = false;
      this.showAddCompany = false;
      this.labourId = "";
      this.ruleForm = {
        name: "",
        ratio: "",
        manager: "",
        taxation: "",
      };
      this.$refs[formName].resetFields();
    },
    alertMeaaage(message, type = "success", title = "创建公司") {
      this.$alert(message, title, {
        confirmButtonText: "确定",
        type,
      });
    },
    getLoading() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      return loading;
    },
  },
};
</script>


<style lang="scss" scoped>
::v-deep .el-input-group__append {
  width: 30px;
}
.page {
  position: relative;
  padding-bottom: 50px;
  min-height: calc(100% - 50px);
}

.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

.filter_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;

  .el-date-editor {
    height: 36px;
    margin-right: 10px;

    ::v-deep .el-input__inner {
      height: 36px;
    }
  }

  .filter_search {
    display: flex;
    align-items: center;

    .filter_search_select {
      width: 80px;
      height: 36px;
      margin-right: 10px;

      ::v-deep .el-input__inner {
        height: 36px;
      }

      ::v-deep .el-input__icon {
        line-height: 36px;
      }
    }

    .filter_search_input {
      width: 350px;
      height: 36px;
      margin-right: 10px;

      ::v-deep .el-input__inner {
        height: 36px;
      }
    }
    > div:not(:first-child) {
      margin-left: 20px;
    }
  }

  .filter_btns {
    display: flex;
    align-items: center;

    > div:not(:first-child) {
      margin-left: 20px;
    }
  }
}

.operations {
  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    color: #406bff;
    white-space: nowrap;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    > div {
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
.draw_body {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & > div {
    width: 48%;
    font-size: 14px;
    color: #606266;
    padding-bottom: 14px;
  }
  .draw_span {
    margin-left: 20px;
  }
  .company_name {
    margin-right: 20px;
  }
}
</style>
